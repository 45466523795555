import OT from "@opentok/client";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";

import styles from "./style.module.scss";

import InputSelect from "../../components/inputSelect/InputSelect";
import apiSocket from "../../services/socket";
import Subtitles from "../../components/subtitles/Subtitles";
import Audio from "../../components/audio/Audio";
import { MdMic, MdMicOff, MdVideocam, MdVideocamOff } from "react-icons/md";
import { useAuth } from "../../contexts/AuthContext";
import moment from "moment";
import { isMobile } from "react-device-detect";

const vonageApiKey = "47751221";

function Meeting() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [sessionId, setSessionId] = useState(null);
  const [token, setToken] = useState(null);
  const [connected, setConnected] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [session, setSession] = useState(null);

  const [device, setDevice] = useState(null);
  const [language, setLanguage] = useState(null);
  const [secondaryLanguage, setSecondaryLanguage] = useState(null);
  const [publisher, setPublisher] = useState(null);
  const [subscriber, setSubscriber] = useState(null);
  const [start, setStart] = useState(null);
  const [timer, setTimer] = useState(null);
  const [mode, setMode] = useState("st,voice,video");
  const [stContainerSize, setStContainerSize] = useState("");

  const { user, isGuest } = useAuth();

  const [muted, setMuted] = useState(false);
  const [camOff, setCamOff] = useState(false);
  const [speaker, setSpeaker] = useState(true);

  useEffect(() => {
    const spMode = searchParams.get("mode");
    const spStContainerSizee = searchParams.get("stContainerSize");

    if (spMode) {
      setMode(spMode);
      if (!spMode.includes("video")) {
        setCamOff(true);
      }
    }

    if (spStContainerSizee) {
      setStContainerSize(spStContainerSizee);
    }
  }, [searchParams]);

  const code = useMemo(() => {
    return searchParams.get("code");
  }, [searchParams]);

  const handleVonageError = (error) => {
    console.log("handleVonageError", error);
    if (error) {
      alert(error.message);
    }
  };

  const initializeVonageSession = () => {
    if (!token || !sessionId) return;
    const session = OT.initSession(vonageApiKey, sessionId);
    setSession(session);

    // Subscribe to a newly created stream
    session.on("streamCreated", function (event) {
      const _subscriber = session.subscribe(
        event.stream,
        "subscriber",
        {
          insertMode: "append",
          width: "100%",
          height: "100%",
          showControls: false,
        },
        handleVonageError
      );
      setSubscriber(_subscriber);
    });

    if (mode.includes("video")) {
      // Create a publisher
      const _publisher = OT.initPublisher(
        "publisher",
        {
          insertMode: "append",
          width: "100%",
          height: "100%",
          audioSource: device,
          showControls: false,
          name: user?.id || "Guest",
        },
        handleVonageError
      );
      setPublisher(_publisher);

      // Connect to the session
      session.connect(token, function (error) {
        // If the connection is successful, initialize a publisher and publish to the session
        if (error) {
          handleVonageError(error);
        } else {
          session.publish(_publisher, handleVonageError);
        }
      });
    } else {
      session.connect(token);
    }
  };

  useEffect(() => {
    if (mode.includes("video")) {
      initializeVonageSession();
    }
  }, [token, sessionId]);

  const registerSocketEvents = () => {
    apiSocket.on("sessions:token", (data) => {
      setConnected(true);
      setToken(data.token);
      setSessionId(data.sessionId);
    });

    apiSocket.on("disconnect", (data) => {
      console.log("disconnected", data);
      setConnected(false);
    });

    apiSocket.on("connect", () => {
      console.log("apiSocket connected");
      setConnected(true);
    });

    apiSocket.on("sessions:update", (data) => {
      console.log("data", data);
      setParticipants(data.participants);
      setStart(data.startedAt);
    });

    apiSocket.on("sessions:forceDisconnect", () => {
      if (session) {
        session.disconnect();
      }
      navigate(-1);
      window.location.reload();
    });

    return () => {
      apiSocket.removeAllListeners();
      apiSocket.emit("sessions:finish", {});
    };
  };

  useEffect(registerSocketEvents, []);

  useEffect(() => {
    if (connected) {
      if (!!language && !!sessionId) {
        setTimeout(() => {
          console.log(
            "emit sessions:joined language",
            language,
            "sessionId",
            sessionId,
            "secondaryLanguage",
            secondaryLanguage
          );
          apiSocket.emit("sessions:joined", {
            language,
            sessionId,
            secondaryLanguage,
          });
        }, 1000);
      }
    } else {
      console.log("trying to reconnect");
      apiSocket.connect();
    }
  }, [connected, sessionId]);

  const joinRoom = async () => {
    apiSocket.emit("sessions:token", { code });
  };

  const setInputDevice = async (device) => {
    setDevice(device);
    if (publisher) {
      await publisher.setAudioSource(device);
    }
  };

  const setOutputDevice = async (device) => {
    await OT.setAudioOutputDevice(device);
  };

  const toggleMute = () => {
    if (!publisher) return;
    if (muted) {
      publisher.publishAudio(true);
      setMuted(false);
    } else {
      publisher.publishAudio(false);
      setMuted(true);
    }
  };

  const toggleCamOff = () => {
    if (!publisher) return;
    if (camOff) {
      publisher.publishVideo(true);
      setCamOff(false);
    } else {
      publisher.publishVideo(false);
      setCamOff(true);
    }
  };

  const toggleSpeaker = () => {
    if (!subscriber) return;
    if (speaker) {
      subscriber.subscribeToAudio(false);
      setSpeaker(false);
    } else {
      subscriber.subscribeToAudio(true);
      setSpeaker(true);
    }
  };

  const leaveMeeting = () => {
    if (window.confirm("Êtes-vous sûr de vouloir quitter la réunion ?")) {
      if (session) {
        session.disconnect();
      }
      navigate("/", { replace: true });
      window.location.reload();
    }
  };

  const reconnectMeeting = () => {
    apiSocket.disconnect();
    setConnected(false);
    apiSocket.connect();
  };

  const showTimer = useCallback(() => {
    if (!start) return null;
    const duration = moment.duration(moment().diff(start));
    setTimer(`${duration.minutes()}:${duration.seconds()}`);
  }, [start]);

  useEffect(() => {
    const interval = setInterval(showTimer, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>iBridgePeople</h1>
        {!sessionId && (
          <div className={styles.settings}>
            <InputSelect
              title={""}
              onLanguageSelected={setLanguage}
              onDeviceSelected={setInputDevice}
              onOutputDeviceSelected={setOutputDevice}
              showLanguageSelect={!publisher}
              mode={mode}
              isGuest={isGuest && code !== "242865"}
            />
            {!sessionId && !!language && (
              <div className={styles.button} onClick={() => joinRoom(true)}>
                <p>{"Start"}</p>
              </div>
            )}
          </div>
        )}
        <span>v2.6.22</span>
      </div>
      <div className={styles.body}>
        {/* <div className={styles.settings}>
          {!sessionId && (
            <InputSelect
              title={"Settings"}
              onLanguageSelected={setLanguage}
              onDeviceSelected={setInputDevice}
              onOutputDeviceSelected={setOutputDevice}
              showLanguageSelect={!publisher}
              mode={mode}
              isGuest={isGuest}
            />
          )}
          {!sessionId && !!language && (
            <div className={styles.button} onClick={() => joinRoom(true)}>
              <p>{"Start"}</p>
            </div>
          )}
          {!!participants &&
            !isGuest &&
            participants.filter((p) => !!p.User).length > 0 && (
              <h3>
                {participants.filter((p) => !!p.User).length} participant
                {participants.filter((p) => !!p.User).length > 1 ? "s" : ""}
              </h3>
            )}
          {!!participants && !isGuest && participants.length > 0 && (
            <div className={styles.participants}>
              {participants
                .filter((p) => !!p.User)
                .map((p) => (
                  <div key={p.id} className={styles.userLine}>
                    <span className={styles.userName}>
                      {p.User.firstname} {p.User.lastname}
                    </span>
                    <span className={styles.userLanguage}>{p.language}</span>
                  </div>
                ))}
            </div>
          )}
          {!isGuest && !!subscriber && (
            <div className={styles.speakerButton} onClick={toggleSpeaker}>
              <p>Speaker : {speaker ? "On" : "Off"}</p>
            </div>
          )}
          {!isGuest && !isMobile && (
            <div className={styles.reconnectButton} onClick={reconnectMeeting}>
              <p>Reconnect</p>
            </div>
          )}
          {!isGuest && !isMobile && (
            <div className={styles.leaveButton} onClick={leaveMeeting}>
              <p>Leave</p>
            </div>
          )}
        </div> */}
        {mode.includes("st") && (
          <>
            <Subtitles
              device={device}
              language={language}
              muted={muted}
              stContainerSize={stContainerSize}
              isGuest={isGuest && code !== "242865"}
              code={searchParams.get("code")}
              setSubtitlesSecondaryLanguage={(lang) =>
                setSecondaryLanguage(lang)
              }
            />
          </>
        )}
        <Audio isVoiceActive={!!mode.includes("voice")} />
        {mode.includes("video") && (
          <div id="videos" className={styles.videos}>
            <div id="subscriber" className={styles.subscriber}></div>
            <div id="publisher" className={styles.publisher}></div>
            <div className={styles.controls}>
              <div onClick={toggleMute} className={styles.muted}>
                {muted ? <MdMicOff size={25} /> : <MdMic size={25} />}
              </div>
              <div onClick={toggleCamOff} className={styles.muted}>
                {camOff ? (
                  <MdVideocamOff size={25} />
                ) : (
                  <MdVideocam size={25} />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Meeting;
